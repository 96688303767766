<script setup lang="ts">
import { KsButton } from "@aschehoug/kloss";

definePageMeta({
  middleware: ["check-auth"],
});

useSeoMeta(useAddSeo());

const auth = useAuthorizeStore();

const settings = useSettingsStore();

const { public: env } = useRuntimeConfig();

const redirectCookie = useRedirectCookie();

function handleIDSPredirect(idsp: Idsp) {
  const redirectTo = useRoute().query.redirectTo?.toString();
  if (redirectTo) {
    redirectCookie.value = redirectTo;
  }

  auth.hasConfirmedSelectedOrganization = false;

  if (!settings.vipps && idsp === "vipps") {
    return;
  }

  auth.logout(true);

  return navigateTo(getAuthUrl(idsp, env), { external: true });
}

useBodyBackground("reset");
</script>

<template>
  <LayoutHeader />
  <LayoutBreadCrumbs
    :text-conversions="{ login: 'Logg inn' }"
    :background="true"
  />
  <main id="main-content">
    <section>
      <h1 class="heading1">
        Logg inn
      </h1>
      <p>
        Ved å logge inn kan du kjøpe læremidler og få oversikt over tidligere
        kjøp. Du kan også se hvilke læremidler du har tilgang til.
      </p>
      <section class="button-group">
        <KsButton
          class="feide-button"
          variant="primary"
          shape="rounded"
          @click="handleIDSPredirect('feide')"
        >
          Logg inn med Feide
        </KsButton>
        <vipps-mobilepay-button
          v-if="settings.vipps"
          type="button"
          brand="vipps"
          language="no"
          variant="primary"
          :rounded="true"
          verb="login"
          :stretched="false"
          :branded="true"
          :loading="false"
          @click="handleIDSPredirect('vipps')"
        />
      </section>
    </section>
    <img
      class="figures"
      src="/images/characters-login.png"
      alt=""
    />
  </main>
  <LayoutFooter />
</template>

<style scoped lang="scss">
#main-content {
  display: flex;
  width: min(95vw, $max-width);
  margin-inline: auto;
  justify-content: flex-start;
  gap: 10rem;
  min-height: calc(75vh - 52px); // 52px is the height of the bread crumbs

  section {
    display: flex;
    flex-direction: column;
  }

  > section {
    margin-block: 5rem;
    color: rgb(var(--skole-color-green-50));
    width: min(90vw, 100%);
    gap: 2rem;

    @media screen and (min-width: $small) {
      width: 50%;
    }
  }

  h1 {
    font-weight: 400;
    line-height: 116.667%;
  }

  p {
    font-size: 1.5rem;
    line-height: 133.333%;
  }

  .feide-button {
    // In order to match the Vipps button
    font-size: 18.5px;
  }

  .button-group {
    gap: 1.25rem;
    align-items: flex-start;
  }

  .figures {
    display: none;
    object-fit: contain;
    width: 30%;

    @media screen and (min-width: $small) {
      display: block;
    }
  }
}
</style>
